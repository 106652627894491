import 'vite/modulepreload-polyfill';
import { lazy, Suspense } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { IRegistrationInitParameters } from '@sparkware/uc-sdk-core';
import { ErrorBoundary } from 'react-error-boundary';
import { IReverseFunnelInitParameters } from '@core/types/reverseFunnel';

const WIDGET_NAME = 'FUNNEL_REGISTRATION';

declare global {
  interface Window {
    [WIDGET_NAME]: {
      init: (props: any) => void;
      initReverseFunnel: (clientConfig: any, reverseFunnelConfig: IReverseFunnelInitParameters) => void;
      close: () => void;
    };
    registration_widget_root: Root;
    dataLayer: any;
  }
}

const App = lazy(() => import('./AppInit'));

const publishInitError = ({ error, clientConfig }: { error: any; clientConfig: IRegistrationInitParameters }) => {
  clientConfig.messageBrokerChannels.session?.topics.appInitFailed.publish(
    { publisher: WIDGET_NAME },
    {
      appID: WIDGET_NAME as any,
      appType: '',
      correlationID: clientConfig.correlationID,
      errorDescription: `Registration widget init failed. error: ${error.message}`
    }
  );
  return null;
};

const initRegistration = async (
  clientConfig: IRegistrationInitParameters,
  reverseFunnelConfig?: IReverseFunnelInitParameters
) => {
  try {
    const container = document.getElementById(
      clientConfig.applicationContext.launchInfo.containerID || ''
    ) as HTMLElement;

    window.registration_widget_root = createRoot(container);
    window.registration_widget_root.render(
      <ErrorBoundary FallbackComponent={(error) => <>{publishInitError({ error, clientConfig })}</>}>
        <Suspense>
          <App clientConfig={clientConfig} reverseFunnelConfig={reverseFunnelConfig} />
        </Suspense>
      </ErrorBoundary>
    );
  } catch (error) {
    publishInitError({ error, clientConfig });
  }
};

const init = async (clientConfig: IRegistrationInitParameters) => {
  initRegistration(clientConfig);
};

const initReverseFunnel = async (
  clientConfig: IRegistrationInitParameters,
  reverseFunnelConfig: IReverseFunnelInitParameters
) => {
  initRegistration(clientConfig, reverseFunnelConfig);
};

const close = () => {
  window.registration_widget_root?.unmount();
};

window[WIDGET_NAME] = { init, initReverseFunnel, close };
